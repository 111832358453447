/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    strong: "strong",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Send a POST request to the following endpoint:"), "\n", React.createElement(_components.p, null, "POST ", React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1/bank-transfer-payments"
  }, "https://api.uat.payroc.com/v1/bank-transfer-payments")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example response")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n    \"channel\": \"POS\",\n    \"processingTerminalId\": \"3204001\",\n    \"operator\": \"Davi-CHP\",\n    \"order\": {\n        \"orderId\": \"CC-12\",\n        \"description\": \"BT ACH - Sale with CC\",\n        \"currency\": \"USD\",\n        \"amount\": 9700\n    },\n    \"paymentMethod\": {\n        \"type\": \"ach\",\n        \"accountType\": \"checking\",\n        \"secCode\": \"web\",\n        \"nameOnAccount\": \"Davi\",\n        \"accountNumber\": \"49100130\",\n        \"routingNumber\": \"292735277\"\n    }\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
